html * {
  box-sizing: border-box;
}
#root {
  height: 100%;
}
html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.Glass {
  border: 2px solid rgba(200,200,200,.5);
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(20px);
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Hero {
  flex: 1;
  text-align: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  display: flex;
}
.Navbar {
  background: blue;
  height: 100px;
}

.Footer {
  font-size: 0.7em;
  text-align: center;
  align-content: center;
  background: white;
  color: #ccc;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
